<template>
  <div id="solo-menu">
    <div class="row">
      <menu-item v-for="(item, i) in Menu_items" :key="i" v-bind="item" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MenuItem from "@/components/MenuItem.vue";

export default {
  name: "SoloMenu",
  components: {
    MenuItem
  },
  data() {
    return {
      Menu_items: [
        {
          name: "Quick Play",
          size: "12",
          color: "primary",
          textcolor: "light",
          image: "fa-bolt.svg",
          link: "/quiz"
        },

        {
          name: "Grammar Quiz",
          size: "6",
          color: "secondary",
          textcolor: "light",
          image: "fa-check.svg",
          link: "/wip/6"
        },
        {
          name: "Accent Challenge",
          size: "6",
          color: "egshell",
          textcolor: "light",
          image: "fa-volume-up.svg",
          link: "/wip/7"
        },
        {
          name: "Vocab Quiz",
          size: "6",
          color: "nappe",
          textcolor: "light",
          image: "fa-book.svg",
          link: "/quiz/3"
        },
        {
          name: "T-learning",
          size: "6",
          color: "primary",
          textcolor: "light",
          image: "fa-coffee.svg",
          link: "/tlearning"
        },
        {
          name: "Speak as a Leader",
          size: "6",
          color: "secondary",
          textcolor: "light",
          image: "fa-microphone.svg",
          link: "/wip/8"
        },
        {
          name: "Finish the Lyrics",
          size: "6",
          color: "egshell",
          textcolor: "light",
          image: "fa-music.svg",
          link: "/quiz/8"
        },
        {
          name: "How to...",
          size: "6",
          color: "nappe",
          textcolor: "light",
          image: "fa-lightbulb-o.svg",
          link: "/wip/9"
        },
        {
          name: "World Business Concepts",
          size: "6",
          color: "primary",
          textcolor: "light",
          image: "fa-btc.svg",
          link: "/quiz/20"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
#solo-menu {
  @extend .text-right;
  width: 100vw;
  @extend .px-3;
  @extend .m-0;
}
</style>
